<template>
    <video
        id="example-video"
        class="video-js"
        autoplay="autoplay"
        controls
        preload="auto"
        width="600"
        height="800"
    >
        <source :src="videoUrl" type="application/x-mpegURL"/>
    </video>
</template>

<script>
import { nextTick } from 'vue'
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default {
    name: "Home",
    setup() {
        const videoUrl = ref("");
        const route = useRoute();

        onMounted(() => {
            videoUrl.value = decodeURIComponent(route.query.url || "");

            handleNextTick();
        });

        const handleNextTick = async ()=>{
            await nextTick();

            var player = videojs('example-video');
            player.play();
        }

        return {
            videoUrl,
        };
    },
};
</script>

<style>
@import "./Home.css";
</style>