<template>
  <router-view/>
</template>
<style>
html, body{
  padding:0; margin:0;
  width:100%; height:100%;
}
#app{
  width:100%; height:100%;
}
</style>